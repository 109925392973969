import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/auth.service';
import { SessionFlow } from '../helper/session-flow';
import { REQUEST, RESPONSE } from '@nguniversal/express-engine/tokens';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  apiURL: string = environment.baseUrl;
  bearer_token: string;
  
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private sessionFlow: SessionFlow,
    @Optional() @Inject(REQUEST) private request: Request,
    @Optional() @Inject(RESPONSE) private response: Response,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }
  
  baseRequestOptions(): object {   
    if (isPlatformBrowser(this.platformId)){
			const userData = JSON.parse(localStorage.getItem('userData'));
      this.bearer_token = _.get(userData, '_token', null);
		}
    
    let headers = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Accept': 'application/json',
        'Access-Control-Expose-Headers': '*',
        //'Authorization': `Bearer ${this.bearer_token}`,
        // 'x-api-key': _.get(this.environment, 'xApiToken', ""),
      }),
      withCredentials: true,
      observe: 'response',
    }
    if(this.authService.isAuth){
      headers.headers = headers.headers.append('Authorization', 'Bearer ' + this.authService.authToken);     
    } 
    
    return headers;
  }

  httpOptions(): object {

    let headers = {
      headers: new HttpHeaders({        
        'Accept': 'application/json',
      })
    };
    if(this.authService.isAuth){
      headers.headers = headers.headers.append('Authorization', 'Bearer ' + this.authService.authToken);     
    }
    
    return headers;
  }
  getHttp<T>(path: string, params: HttpParams): Observable<T> {
    let options: object = {
      headers: new HttpHeaders({        
        'Accept': 'application/json',
      }),
      params: params,
    }
    return this.http.get<T>(`${this.apiURL}/${path}`, options);
  }
  getAll<T>(path: string): Observable<T> {
    return this.http.get<T>(`${this.apiURL}/${path}`, this.httpOptions());
  }
  
  get<T>(path: string): Observable<T> {    
    return this.http.get<T>(`${this.apiURL}/${path}`, this.httpOptions());
  }
  
  postApi(path: string, data?: object): Observable<any> {
    return this.http.post<any>(`${this.apiURL}/${path}`, data, this.baseRequestOptions());
  }

  patchApi(path: string, data?: object): Observable<any> {
    return this.http.patch<any>(`${this.apiURL}/${path}`, data);
  }

  putApi(path: string, data?: object): Observable<any> {
    return this.http.put<any>(`${this.apiURL}/${path}`, data, this.baseRequestOptions());
  }

  deleteApi<T>(path: string, body: any = {}): Observable<T> {
    const options ={...this.baseRequestOptions(), ...{body: body}};
    return this.http.delete<T>(`${this.apiURL}/${path}`, options);
  } 
}
