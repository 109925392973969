
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, of, Subscription } from 'rxjs';
import { ApiService } from 'src/app/shared/services/api.service';
import { Category } from '../model/category.model';
import { Menu } from 'src/app/shared/services/nav.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  public category = new BehaviorSubject<Category>(null);
  public categoryData = this.category.asObservable();

  constructor(private api: ApiService) { }

  // categoryResolve(): Subscription{
  //   return this.categoryData.subscribe({
  //     next(response) { console.log(response); },
  //     error(err) { console.error('Error: ' + err); },
  //     complete() { console.log('Completed'); }
  //   });
  // }

  isCategory(slug: string){
    return this.getCategory(slug).subscribe(res => this.category.next(res));
  }
  getAllCategories(): Observable<Category[]>{
    return this.api
			.getAll<any>(`categories`)
			.pipe(map((resp) => resp));
  }

  getCategory(category_slug: string): Observable<any> {
    return this.api
      .get<{ category: Category }>(`category/${category_slug}`)
      .pipe(map((resp) => resp.category));
  }
  
}
