import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map} from 'rxjs';
import { ApiService } from './api.service';
import { AuthService } from 'src/app/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { Product } from 'src/app/core/model/product.model';
import { SessionFlow } from '../helper/session-flow';
import { Cart } from 'src/app/core/model/cart.model';
import * as _ from 'lodash';

const state = {
  cart: JSON.parse(localStorage['cartItems'] || '[]'),
  getcart: JSON.parse(localStorage['setCartItems'] || '[]')
}

@Injectable({
  providedIn: 'root'
})
export class CartService {

  cartItems$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  device_id: any;
  public OpenCart: boolean = false;
  public Currency = { name: 'Dollar', currency: 'AED', price: 1 }
 
  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private authService: AuthService,
    private toastrService: ToastrService,
    private sessionFlow: SessionFlow
   
  ) { 
    this.cartItems$.next(state.getcart);
    //this.getCart();
    this.device_id = this.sessionFlow.deviceId;
  }

  /*
    ---------------------------------------------
    -----------------  Cart  --------------------
    ---------------------------------------------
  */

  // Get Cart Items
  public get cartItems(): Observable<Product[]> {
    const itemsStream = new Observable(observer => {
      observer.next(state.cart);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  public get allCartItems(): Observable<Cart> {   
    return this.cartItems$;
  }

  getCartData(): Observable<Cart>{
    let url: string;
    let headers = new HttpHeaders({        
        'Accept': 'application/json',
      });
   
    const params = new HttpParams({
      fromObject: {device_id: this.device_id}
    }); 
    
    if(this.authService.isAuth){
      url = `${environment.baseUrl}/checkout/cart?token=true`;
      headers = headers.append('Authorization', 'Bearer ' + this.authService.authToken);
    } else {
      url = `${environment.baseUrl}/checkout/cart`;     
    }
    let options = {
      headers: headers,
      params: params,
      withCredentials: true
    } 
    return this.http.get<Cart>(url, options).pipe(map(res => res));
  }
  
  getCart() {    
    this.getCartData().subscribe({
      next: (response: any) => {            
        if(!_.isEmpty(response.data)){
          //if(! localStorage.getItem("setCartItems") || localStorage.getItem("setCartItems") === 'null'){          
          localStorage.setItem("setCartItems", JSON.stringify(response.data)); 
          //}
          state.getcart = response.data;
          this.cartItems$.next(state.getcart);
        } else {
          this.cartItems$.next([]);
          localStorage.removeItem("setCartItems");
        } 
      },
      error: () => this.cartItems$.next([])
    });

  }


  // Add to Cart
  public addToCart(product) {
    const items = product;

    this.OpenCart = true; // If we use cart variation modal
    
    let productId: number;
    let formData: any = {};
   
    if(items.parent_id) {

      let select_attr = {};
      
      for (let i = 0; i < items.super_attribute.length; i++) {
        select_attr[items.super_attribute[i].attribute_id] = items.super_attribute[i].attribute_option_id;
      }

      productId = items.parent_id;
      formData = {
        "product_id": items.parent_id,
        "quantity": items.quantity,
        "selected_configurable_option": items.id,
        "super_attribute": select_attr,
        "device_id": this.device_id
      }
    } else {
      productId = items.id;
      formData = {
        product_id: items.id,
        quantity: items.quantity,
      }
    }
       
    this.addProduct(productId, formData).subscribe((res) => {
     
      if(res.status){
        localStorage.setItem("setCartItems", JSON.stringify(res.data));
        state.getcart = res.data
        this.cartItems$.next(state.getcart);
        this.toastrService.success(res.message, 'Add');
      } else {
        this.toastrService.error(res.message, 'Error');
      }
     
    });
    return true;
  }

  addProduct(product_id: number, postData: any):Observable<any> {

    if(this.authService.isAuth){
      postData = {...postData, ...{token: true}};
    } 
    
    return this.apiService.postApi(`checkout/cart/add/${product_id}`, postData)
    .pipe(
      map(res => res.body)      
    );
    
    
  }

  public updateCartQuantity(cart_id: number, item: any): any {
    const qty = item.quantity;
    let postData = {};
    if(this.authService.isAuth){
      postData = {
        token: true,
        "qty" : {
          [item.id] : qty
        },
        device_id: this.device_id
      }
    } else {
      postData = {
        "qty" : {
          [item.id] : qty
        },
        device_id: this.device_id
      }
    }
   
    return this.apiService.postApi(`checkout/cart/update`, postData)
    .pipe(map((res) => res.body))
    .subscribe(res => {
       
      if(!_.isEmpty(res.data)){
        localStorage.setItem("setCartItems", JSON.stringify(res.data));
        state.getcart = res.data;
        this.cartItems$.next(state.getcart);
      } else {
        this.cartItems$.next([]);
        localStorage.removeItem("setCartItems");
      }
      if(res.status){
        this.toastrService.success(res.message, 'Update');
      } else {
        this.toastrService.error(res.message, 'Error');
      }
      
    });
  }

  // Calculate Stock Counts
  public calculateStockCounts(product, quantity) {
    const qty = product.quantity + quantity
    const stock = product.stock
    if (stock < qty || stock == 0) {
      this.toastrService.error('You can not add more items than available. In stock '+ stock +' items.');
      return false
    }
    return true
  }

  // Remove Cart items
  public removeCartItem(item): any {    
    return this.apiService.postApi(`checkout/cart/remove-item/${item.id}`, {device_id: this.device_id})
    .pipe(map(res => res.body))
    .subscribe((res: any)=> {       
        if(!_.isEmpty(res.data)){
          localStorage.setItem("setCartItems", JSON.stringify(res.data));
          state.getcart = res.data;
          this.cartItems$.next(state.getcart);
        } else {
          this.cartItems$.next([]);
          localStorage.removeItem("setCartItems");
        }
        if(res.status){
          this.toastrService.success(res.message, 'Remove');
        } else {
          this.toastrService.error(res.message, 'Error');
        }
       
    });
   
  }

  // Total amount 
  public cartTotalAmount(): Observable<number> {
    return this.cartItems.pipe(map((product: Product[]) => {
      
      return product.reduce((prev, curr: Product) => {
        let price = curr.price;
       
        if(curr.discount) {
          price = curr.price - (curr.price * curr.discount / 100)
        }
        return (prev + price * curr.quantity) * this.Currency.price;
      }, 0);
    }));
  }

  storeCartAddress(address: any, guest: boolean): Observable<any>{
    console.log(guest);
    let url:string = '';
    if(!guest){
      url = 'checkout/save-address?token=true';
    } else {
      url = 'checkout/save-address';
    }

    return this.apiService.postApi(url, address).pipe(map((res) => res.body));
  }

  storeShippingMethod(shipping: any, guest: boolean): Observable<any>{    
    let url:string = '';
    if(!guest){
      url = 'checkout/save-shipping?token=true';
    } else {
      url = 'checkout/save-shipping';
    }
    return this.apiService.postApi(url, shipping).pipe(map((res) => res.body))
  }

  storePaymentMethod(payment: any, guest: boolean = true): Observable<any>{
    let url:string = '';
    if(!guest){
      url = 'checkout/save-payment?token=true';
    } else {
      url = 'checkout/save-payment';
    }
    return this.apiService.postApi(url, payment).pipe(map((res) => res.body))
  }

  storeCartOrder(guest: boolean = true): Observable<any>{
    let url:string = '';
    if(!guest){
      url = 'checkout/save-order?token=true';
    } else {
      url = 'checkout/save-order';
    }
    return this.apiService.postApi(url).pipe(map((res) => res.body))
  }

  postCheckout(form_data: any): Observable<any>{
    let url:string = 'checkout/process-order';
    form_data = {...form_data, ...{device_id: this.device_id}};
    return this.apiService.postApi(url, form_data).pipe(map((res) => res.body))
  }
}
