import { Component, OnInit, Output, Input, EventEmitter, Inject, PLATFORM_ID, ElementRef } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ChangeContext, Options } from 'ng5-slider';

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss']
})
export class PriceComponent implements OnInit {
  
  // Using Output EventEmitter
  @Output() priceFilter : EventEmitter<any> = new EventEmitter<any>();
  @Output() hideCollapse : EventEmitter<any> = new EventEmitter<any>();

  // define min, max and range
  @Input() min: number;
  @Input() max: number;

  public collapse: boolean = false;
  public isBrowser: boolean = false;
  public mobileSidebar: boolean = false;

  public price: any = [];

  options: Options = {
    floor: 0,
    ceil: 1000
  };
 
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private el: ElementRef
  ) { 
    if (isPlatformBrowser(this.platformId)) {
      this.isBrowser = true; // for ssr
    }
  }
  
  ngOnInit(): void {  }
  ngOnChanges(): void {
    this.options = {
      floor: this.min || 0,
      ceil: this.max || 0
    }
    this.min = this.options.floor;
    this.max = this.options.ceil;
  }
  onUserChange(changeContext: ChangeContext): void {
    let floorStyle = this.el.nativeElement.querySelector('.ng5-slider-floor');
    let ceilStyle = this.el.nativeElement.querySelector('.ng5-slider-ceil');
    if(this.options.floor < changeContext.value ){
      floorStyle.style.opacity = 0;
    } else {
      floorStyle.style.opacity = 1;
    }
    if(this.options.ceil > changeContext.highValue){      
      ceilStyle.style.opacity = 0;
    } else {
      ceilStyle.style.opacity = 1;
    }
  }
  
  // Range Changed
  appliedFilter(event: any) {
    
    let filterData = {};
    //this.price = { minPrice: event.value, maxPrice: event.highValue };
    //this.price = [...this.price, [...event.value, ...event.highValue]];
    filterData['price'] = event.value + ',' + event.highValue;    
    this.priceFilter.emit(filterData);
  }
  toggleCollapse(){
    if(!this.collapse){
      this.collapse = true;
    } else {
      this.collapse = false;
    }
    //this.collapse = !this.collapse; 
    this.hideCollapse.emit(this.collapse);
  }
  toggleMobileSidebar() {
    this.mobileSidebar = !this.mobileSidebar;
  }


}
