import { Component, OnInit, Input, ViewChild, ElementRef, SimpleChange } from '@angular/core';
import { QuickViewComponent } from "../../modal/quick-view/quick-view.component";
import { CartModalComponent } from "../../modal/cart-modal/cart-modal.component";
import { Product } from 'src/app/core/model/product.model';
import { ProductService } from "../../../services/product.service";
import { ProductAttributeSlider } from 'src/app/shared/data/slider';
import { OwlOptions } from 'ngx-owl-carousel-o';


@Component({
  selector: 'app-product-box-slider',
  templateUrl: './product-box-slider.component.html',
  styleUrls: ['./product-box-slider.component.scss']
})
export class ProductBoxSliderComponent implements OnInit {

  
  @Input() product: Product;
  @Input() currency: any = this.productService.Currency; // Default Currency 
  @Input() thumbnail: boolean = false; // Default False 
  @Input() onHowerChangeImage: boolean = false; // Default False
  @Input() cartModal: boolean = false; // Default False
  @Input() loader: boolean = false;  
  @Input() enableButton: boolean = true;
  @Input() enableCart: boolean = true;
  
  @ViewChild("quickView") QuickView: QuickViewComponent;
  @ViewChild("cartModal") CartModal: CartModalComponent;
 
  public ImageSrc : string;
  errorMsg: boolean = false;
  errorTxt: any = [];
  cartProduct: any = {};
  queryParams: any = {};
 
  @Input() sliderConfig: OwlOptions;

  constructor(   
    private productService: ProductService
  ) { }

  ngOnInit(): void {
    
    if(this.loader) {
      setTimeout(() => { this.loader = false; }, 2000); // Skeleton Loader
    }
   
    this.ImageSrc = '';
    //console.log(this.sliderConfig);
  }
  ngOnChanges(change: SimpleChange): void{
    //console.log('product box', change);
  }
  

  addToWishlist(product: any) {
    this.productService.addToWishlist(product);
  }

  addToCompare(product: any) {
    this.productService.addToCompare(product);
  }

}
