<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
  <div class="mobile-fix-option"></div>
  <div class="top-header" *ngIf="topbar">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-8">
          <div class="header-contact">
            <ul>
              <!-- <li>Welcome to Our store Multikart</li> -->
              <!-- <li><i class="fa fa-phone" aria-hidden="true"></i> <a href="tel:+971 504739900">+971 504 739 900</a> / <a href="tel:+971 586827500">+971 586 827 500</a></li> -->
              <li><i class="fa fa-phone" aria-hidden="true"></i> <a href="tel:+971506062720">+971506062720</a> - Delivery Contact Number</li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-4 text-end">
          <ul class="header-dropdown">
            <!-- <li class="compare">
              <a [routerLink]="['/shop/compare']"><i class="fa fa-random" aria-hidden="true"></i>Compare</a>
            </li> -->
            <!-- <li class="mobile-wishlist">
              <a [routerLink]="['/shop/wishlist']"><i class="fa fa-heart-o" aria-hidden="true"></i>Wishlist</a>
            </li> -->
            <li class="onhover-dropdown mobile-account">
              <a><i class="fa fa-user" aria-hidden="true"></i> My Account</a>
              <ul class="onhover-show-div">
                <ng-container *ngIf="!this.isAuthenticated">
                  <li>
                    <a [routerLink]="['/register']">Create an Account</a>
                  </li>
                  <li>
                    <a [routerLink]="['/login']">Sign In</a>
                  </li>
                </ng-container>
                <ng-container *ngIf="this.isAuthenticated">
                  <li>
                    <a [routerLink]="['/dashboard']">Dashboard</a>
                  </li>
                  <li>
                    <a (click)="onLogout()">Logout</a>
                  </li>
                </ng-container>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="main-menu">
          <div class="menu-left">            
            <div class="brand-logo">
              <a routerLink="/" id="headerlogo">
                <img [src]="themeLogo" class="img-fluid" alt="logo" class="logo">
              </a>
            </div>
          </div>
          <div class="menu-right pull-right">
            <div>
              <app-menu></app-menu>
            </div>
            <div>
              <app-settings></app-settings>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
