export class User {
    constructor(        
        public email: string,
        public first_name: string,
        public last_name: string,
        public name: string,
        public phone: string,
        public id: string,
        private _token: string,
        private _tokenExpirationDate: Date,
  
    ) {}
  
    get token() {
        if(!this._tokenExpirationDate || new Date > this._tokenExpirationDate) {
            return null;
        }
  
        return this._token;
    }
}
