import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BarRatingModule } from "ngx-bar-rating";
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { TranslateModule } from '@ngx-translate/core';
import { Ng5SliderModule } from 'ng5-slider';
import { NgxPaginationModule } from 'ngx-pagination';

// Header and Footer Components
import { HeaderOneComponent } from './header/header-one/header-one.component';
import { FooterOneComponent } from './footer/footer-one/footer-one.component';

// Components
import { MenuComponent } from './components/menu/menu.component';
import { SettingsComponent } from './components/settings/settings.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ProductBoxOneComponent } from './components/product/product-box-one/product-box-one.component';
import { ProductBoxSliderComponent } from './components/product/product-box-slider/product-box-slider.component';
// Modals Components
import { NewsletterComponent } from './components/modal/newsletter/newsletter.component';
import { QuickViewComponent } from './components/modal/quick-view/quick-view.component';
import { CartModalComponent } from './components/modal/cart-modal/cart-modal.component';
import { CartVariationComponent } from './components/modal/cart-variation/cart-variation.component';
import { VideoModalComponent } from './components/modal/video-modal/video-modal.component';
import { SizeModalComponent } from './components/modal/size-modal/size-modal.component';
import { AgeVerificationComponent } from './components/modal/age-verification/age-verification.component';

// Skeleton Loader Components
import { SkeletonProductBoxComponent } from './components/skeleton/skeleton-product-box/skeleton-product-box.component';

// Tap To Top
import { TapToTopComponent } from './components/tap-to-top/tap-to-top.component';

// Pipes
import { DiscountPipe } from './pipes/discount.pipe';

//collection
import { GridComponent } from '../shop/collection/widgets/grid/grid.component';
import { PaginationComponent } from '../shop/collection/widgets/pagination/pagination.component';
import { BrandsComponent } from '../shop/collection/widgets/brands/brands.component';
import { PriceComponent } from '../shop/collection/widgets/price/price.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { TooltipModule } from '../common/ui/tooltip/tooltip.module';
import { AlertModule } from '../common/ui/_alert/alert.module';
import { LoaderComponent } from '../common/ui/loader/loader.component';
import { TruncatePipe } from './pipes/truncate.pipe';



@NgModule({
  declarations: [
    HeaderOneComponent,
    FooterOneComponent,
    MenuComponent,
    SettingsComponent,
    BreadcrumbComponent,
    ProductBoxOneComponent,
    ProductBoxSliderComponent,
    NewsletterComponent,
    QuickViewComponent,
    CartModalComponent,
    CartVariationComponent,
    VideoModalComponent,
    SizeModalComponent,
    AgeVerificationComponent,
    SkeletonProductBoxComponent,
    TapToTopComponent,
    DiscountPipe,
    GridComponent,
    PaginationComponent,
    BrandsComponent,
    PriceComponent,
    SpinnerComponent,
    LoaderComponent,
    TruncatePipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CarouselModule,
    BarRatingModule,
    LazyLoadImageModule,
    NgxSkeletonLoaderModule,
    TranslateModule,
    Ng5SliderModule,
    NgxPaginationModule,
    TooltipModule,
    AlertModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CarouselModule,
    BarRatingModule,
    LazyLoadImageModule,
    NgxSkeletonLoaderModule,
    TranslateModule,
    Ng5SliderModule,
    NgxPaginationModule,
    LoaderComponent,
    HeaderOneComponent,
    FooterOneComponent,
    BreadcrumbComponent,
    ProductBoxOneComponent,
    ProductBoxSliderComponent,
    NewsletterComponent,
    QuickViewComponent,
    CartModalComponent,
    CartVariationComponent,
    VideoModalComponent,
    SizeModalComponent,
    AgeVerificationComponent,
    SkeletonProductBoxComponent,
    TapToTopComponent,
    DiscountPipe,
    TruncatePipe,
    GridComponent,
    PaginationComponent,
    BrandsComponent,
    PriceComponent,
    SpinnerComponent,
    TooltipModule,
    AlertModule
  ]
})
export class SharedModule { }
