import { Component, OnInit, Input, ViewChild, ElementRef, SimpleChange } from '@angular/core';
import { ActivatedRoute, Params, QueryParamsHandling, Router } from '@angular/router';
import { QuickViewComponent } from "../../modal/quick-view/quick-view.component";
import { CartModalComponent } from "../../modal/cart-modal/cart-modal.component";
import { Product } from 'src/app/core/model/product.model';
import { CartService } from 'src/app/shared/services/cart.service';
import { ProductService } from "../../../services/product.service";
import { ProductAttributeSlider } from 'src/app/shared/data/slider';
import { ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs';
import { DeviceDetectorService } from 'src/app/core/services/device-detector.service';


@Component({
  selector: 'app-product-box-one',
  templateUrl: './product-box-one.component.html',
  styleUrls: ['./product-box-one.component.scss']
})
export class ProductBoxOneComponent implements OnInit {

  @Input() product: Product;
  @Input() currency: any = this.productService.Currency; // Default Currency 
  @Input() thumbnail: boolean = false; // Default False 
  @Input() onHowerChangeImage: boolean = false; // Default False
  @Input() cartModal: boolean = false; // Default False
  @Input() loader: boolean = false;  
  @Input() enableButton: boolean = true;
  @Input() enableCart: boolean = true;

  width:number =0;
  
  @ViewChild("quickView") QuickView: QuickViewComponent;
  @ViewChild("cartModal") CartModal: CartModalComponent;
 
  public ImageSrc : string;
  errorMsg: boolean = false;
  errorTxt: any = [];
  cartProduct: any = {};
  queryParams: any = {};
  params: any = {};
 
  public sliderConfig: any = ProductAttributeSlider;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private productService: ProductService,
    private cartService: CartService,
    private toastService: ToastrService
  ) { 
    this.route.queryParams.subscribe((params: any) => {
      if(params){
        this.params = params;
      }
    })
  }

  ngOnInit(): void {
    // console.log('===Product===', this.product);
    // console.log('this.params', this.params);
    if(this.loader) {
      setTimeout(() => { this.loader = false; }, 2000); // Skeleton Loader
    }
    if(this.product?.variants?.length){
      this.product?.super_attributes.filter(att => {
        att.options.filter(opt => opt.selected = false);
      });
      this.product.variants.filter((variant, i, arr) => {       
        this.product.price = arr[0].price;
        if(arr[0].special_price > 0 && arr[0].special_price != null){            
          this.product.special_price = arr[0].special_price;
        }
        this.product.stocks = arr[0].stocks;
        let combination = [];
        
        this.product?.super_attributes?.forEach((attrb) => {         
          let key = Object.keys(variant).find(key => key == attrb.code); 
          attrb.options.filter((opt) => {                     
            if(key == attrb.code && variant[key] == opt.id){           
              combination.push(variant[key])
              Object.assign(variant, {combination});
            }
            
          });         
          if(Object.keys(this.params).includes(attrb.code) && this.params[attrb.code]?.length){                      
            let data = this.params[attrb.code]; 
            data = data.split(/\s*,\s*/);        
            data.forEach(id => { 
              attrb.options.find(opt => (opt.id == id) ? attrb.selectedValue = opt : attrb.selectedValue = '').selected = true; 
                            
            });       
            
          } else {                      
            attrb.options.filter((value, key)=> (key==0) ? value.selected = true  : value.selected = false)
            attrb.options.find(opt => opt.selected ? attrb.selectedValue = opt : attrb.selectedValue = '');  
          }
                 
         
        });     
        
      });
    }
    
    //console.log(this.product.extra_attributes)
    
    this.product?.super_attributes.map(attr => attr.selectedValue.selected ? this.queryParams = {...this.queryParams, ...{[`${attr.code}`] : attr.selectedValue.value }} : this.queryParams = {})
    
    //console.log("Product===", this.product);
    //console.log("Product===", this.selectedVariant);

    this.ImageSrc = '';
    
  }
  ngOnChanges(change: SimpleChange): void{
    //console.log('product box', change);
  }
  changeVariant(data, each){
    data.options.filter(opt => opt.selected = false);
    each.selected = true;
    data.selectedValue = each;
    if(this.queryParams[data.code]){
      this.queryParams[data.code] = data.selectedValue.value;
    } else {
      this.queryParams = {...this.queryParams, ...{[`${data.code}`] : data.selectedValue.value }};
    }

    let combination = this.product.super_attributes?.reduce((acc, obj) => {
      return [...acc, obj.selectedValue.id];
    }, []);
   
    let variant = this.product.variants.find(variant => variant.combination.every((val, index) => val === combination[index]));
   
    this.product.price = variant?.price;
    this.product.special_price = variant?.special_price;    
    
    // console.log("Product===", this.product);
    // console.log('after',data);
    // console.log(each);
  }
  
  addToCart() {
    let attributeOption = [];
    let option: {attribute_id: any, attribute_option_id: any} = {attribute_id: null, attribute_option_id: null};

    let combination = this.product.super_attributes?.reduce((acc, obj) => {
      return [...acc, obj.selectedValue.id];
    }, []);
   
    if(this.product.variants.length){
      this.product.variants?.map((variant: any) => {
      
        if(Array.isArray(variant.combination) && Array.isArray(combination) 
        && variant.combination.length == combination.length
        && variant.combination.every((val, index) => val === combination[index])
        ){
         
          this.product?.super_attributes?.filter((attribute) => {
            option.attribute_id = attribute.id
            option.attribute_option_id = attribute.selectedValue.id;
            attributeOption = [...attributeOption, {...option}];
          });

          this.product.price = variant.price;
          if(variant.special_price > 0 && variant.special_price != null){
            this.product.special_price = variant.special_price;
          }

          this.cartProduct = {...variant, ...{super_attribute: attributeOption}, ...{quantity: 1}};
        }
      });
      
    } else {
      this.cartProduct = {...this.product, ...{quantity: 1}};
    }
    
    if(this.cartProduct.stocks.total_stock < this.cartProduct.quantity || this.cartProduct.stocks.out_of_stock){
      this.toastService.error('Out of Stock');
    } else {
      this.cartService.addToCart(this.cartProduct);     
    }
  
    
    
  }

  addToWishlist(product: any) {
    this.productService.addToWishlist(product);
  }

  addToCompare(product: any) {
    this.productService.addToCompare(product);
  }

}
