import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
//import { Product } from '../classes/product';
import { ApiService } from './api.service';
import { Product } from 'src/app/core/model/product.model';


const state = {
  products: JSON.parse(localStorage['products'] || '[]'),
  wishlist: JSON.parse(localStorage['wishlistItems'] || '[]'),
  compare: JSON.parse(localStorage['compareItems'] || '[]'),
}

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  //public Currency = { name: 'Dollar', currency: 'USD', price: 1 } // Default Currency
  public Currency = { name: 'Dollar', currency: 'AED', price: 1 }
  public OpenCart: boolean = false;
  public Products;
  cart_session_id: string;
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  
  public productSub = new BehaviorSubject<Product>(null);
  public productData = this.productSub.asObservable();
  
  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private toastrService: ToastrService,
  ) {
  }

  isProduct(slug: string): Observable<any>{
    return of(this.getProductBySlug(slug).subscribe(res => res));
  }

  getFilterByCategory():Observable<any> {
    return this.apiService.get<any>('filter-by').pipe(map((res) => res));
  }
  
  getCategoryProducts(category_id: number): Observable<{meta: any, link: any, data: Product[], filters: any, categories: any}>{
    return this.apiService
      .getAll<any>(`products?category_id=${category_id}`)
      .pipe(map((res) => res));
  }

  getProductFilters(filters): Observable<{meta: any, link: any, data: Product[], filters: any, categories: any}>{   
    const params = new HttpParams({
      fromObject: filters
    });    
    return this.apiService.getHttp<any>(`products`, params)
      .pipe(map((res) => res));
  }

  getProductBySlug(slug: string): Observable<Product>{
    return this.apiService
      .get<{product: Product}>(`product/${slug}`)
      .pipe(map((res) => res.product));
  }
  
  getProductAttributes(): Observable<any>{
    return this.apiService.get<any>(`attributes?pagination=0`)
      .pipe(map((res) => res));
  }

  getTrendingProducts(): Observable<Product[]>{
    return this.apiService.getAll<{products: Product[]}>(`trending-products`).pipe(map((resp) => resp.products));
  }

  getFeaturedProducts(): Observable<Product[]>{
    return this.apiService.getAll<{products: Product[]}>(`featured-products`).pipe(map((resp) => resp.products));
  }
  getNewProducts(): Observable<Product[]>{
    return this.apiService.getAll<{products: Product[]}>(`new-products`).pipe(map((resp) => resp.products));
  }
  getBestsellerProducts(): Observable<Product[]>{
    return this.apiService.getAll<{data: Product[]}>(`products?bestseller=1&limit=6`).pipe(map((resp) => resp.data));
  }
  getMattressesCollection(): Observable<Product[]>{
    return this.apiService.getAll<{products: Product[]}>(`mattresses-collection`).pipe(map((resp) => resp.products));
  }
  getBedsCollection(): Observable<Product[]>{
    return this.apiService.getAll<{products: Product[]}>(`beds-collection`).pipe(map((resp) => resp.products));
  }
  getAccessoriesCollection(): Observable<Product[]>{
    return this.apiService.getAll<{products: Product[]}>(`accessories-collection`).pipe(map((resp) => resp.products));
  }
  getRelatedProducts(slug: string): Observable<Array<Product>>{
    return this.apiService.getAll<{data: Product[]}>(`related-product/${slug}`).pipe(map((resp) => resp.data));
  }
  /*
    ---------------------------------------------
    ---------------  Product  -------------------
    ---------------------------------------------
  */

  // Product
  // private get products(): Observable<Product[]> {
  //   this.Products = this.http.get<Product[]>('assets/data/products.json').pipe(map(data => data));
  //   this.Products.subscribe(next => { localStorage['products'] = JSON.stringify(next) });
  //   return this.Products = this.Products.pipe(startWith(JSON.parse(localStorage['products'] || '[]')));
  // }

  // Get Products
  // public get getProducts(): Observable<Product[]> {
  //   return this.products;
  // }

  // Get Products By Slug
  // public getProductBySlug(slug: string): Observable<Product> {
  //   return this.products.pipe(map(items => { 
  //     return items.find((item: any) => { 
  //       return item.title.replace(' ', '-') === slug; 
  //     }); 
  //   }));
  // }


  /*
    ---------------------------------------------
    ---------------  Wish List  -----------------
    ---------------------------------------------
  */

  // Get Wishlist Items
  public get wishlistItems(): Observable<Product[]> {
    const itemsStream = new Observable(observer => {
      observer.next(state.wishlist);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  // Add to Wishlist
  public addToWishlist(product): any {
    const wishlistItem = state.wishlist.find(item => item.id === product.id)
    if (!wishlistItem) {
      state.wishlist.push({
        ...product
      })
    }
    this.toastrService.success('Product has been added in wishlist.');
    localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
    return true
  }

  // Remove Wishlist items
  public removeWishlistItem(product: Product): any {
    const index = state.wishlist.indexOf(product);
    state.wishlist.splice(index, 1);
    localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
    return true
  }

  /*
    ---------------------------------------------
    -------------  Compare Product  -------------
    ---------------------------------------------
  */

  // Get Compare Items
  public get compareItems(): Observable<Product[]> {
    const itemsStream = new Observable(observer => {
      observer.next(state.compare);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  // Add to Compare
  public addToCompare(product): any {
    const compareItem = state.compare.find(item => item.id === product.id)
    if (!compareItem) {
      state.compare.push({
        ...product
      })
    }
    this.toastrService.success('Product has been added in compare.');
    localStorage.setItem("compareItems", JSON.stringify(state.compare));
    return true
  }

  // Remove Compare items
  public removeCompareItem(product: Product): any {
    const index = state.compare.indexOf(product);
    state.compare.splice(index, 1);
    localStorage.setItem("compareItems", JSON.stringify(state.compare));
    return true
  }

  
  /*
    ---------------------------------------------
    ------------  Filter Product  ---------------
    ---------------------------------------------
  */

  // Get Product Filter
  // public filterProducts(filter: any): Observable<Product[]> {
  //   return this.products.pipe(map(product => 
  //     product.filter((item: Product) => {
  //       if (!filter.length) return true
  //       const Tags = filter.some((prev) => { // Match Tags
  //         if (item.tags) {
  //           if (item.tags.includes(prev)) {
  //             return prev
  //           }
  //         }
  //       })
  //       return Tags
  //     })
  //   ));
  // }

  // Sorting Filter
  public sortProducts(products: Product[], payload: string): any {
    
    if(payload === 'ascending') {
      return products.sort((a, b) => {
        if (a.id < b.id) {
          return -1;
        } else if (a.id > b.id) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'a-z') {
      return products.sort((a, b) => {
        if (a.title < b.title) {
          return -1;
        } else if (a.title > b.title) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'z-a') {
      return products.sort((a, b) => {
        if (a.title > b.title) {
          return -1;
        } else if (a.title < b.title) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'low') {
      return products.sort((a, b) => {
        if (a.price < b.price) {
          return -1;
        } else if (a.price > b.price) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'high') {
      return products.sort((a, b) => {
        if (a.price > b.price) {
          return -1;
        } else if (a.price < b.price) {
          return 1;
        }
        return 0;
      })
    } 
  }

  /*
    ---------------------------------------------
    ------------- Product Pagination  -----------
    ---------------------------------------------
  */
  public getPager(totalItems: number, currentPage: number = 1, pageSize: number = 16) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // Paginate Range
    let paginateRange = 3;

    // ensure current page isn't out of range
    if (currentPage < 1) { 
      currentPage = 1; 
    } else if (currentPage > totalPages) { 
      currentPage = totalPages; 
    }
    
    let startPage: number, endPage: number;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else if(currentPage < paginateRange - 1){
      startPage = 1;
      endPage = startPage + paginateRange - 1;
    } else {
      startPage = currentPage - 1;
      endPage =  currentPage + 1;
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

}
