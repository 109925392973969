<div class="icon-nav">
  <ul>
    <li class="onhover-div mobile-search search-widgets" id="search-widgets">
      <div (click)="searchToggle()">
        <img src="assets/images/icon/search.png" class="img-fluid" alt="">
        <i class="ti-search"></i>
      </div>
      <div id="search-overlay" class="search-overlay" [ngStyle]="{'display': search ? 'block' : 'none' }">
        <div> 
            <span class="closebtn" title="Close Overlay" (click)="searchToggle()">×</span>
            <div class="overlay-content">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <form>
                                <div class="form-group">
                                    <input type="text" class="form-control" id="exampleInputPassword1" placeholder="Search a Product">
                                </div>
                                <button type="submit" class="btn btn-primary"><i class="fa fa-search"></i></button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </li>
    <!-- <li class="onhover-div mobile-setting">
      <div><img src="assets/images/icon/setting.png" class="img-fluid" alt="settong">
        <i class="ti-settings"></i></div>
      <div class="show-div setting">
        <h6>language</h6>
        <ul>
          <li *ngFor="let language of languages">
            <a href="javascript:void(0)" (click)="changeLanguage(language.code)">{{ language.name }}</a>
          </li>
        </ul>
        <h6>currency</h6>
        <ul class="list-inline">
          <li *ngFor="let currency of currencies">
            <a href="javascript:void(0)" (click)="changeCurrency(currency)">{{ currency.name }}</a>
          </li>
        </ul>
      </div>
    </li> -->
    <li class="onhover-div mobile-cart">
      <div>
        <a (click)="sidecartToggle()"><!--[routerLink]="['/checkout/cart']"-->
          <img src="assets/images/icon/cart.png" class="img-fluid" alt="">
          <i class="ti-shopping-cart"></i>
        </a>
      </div>
      <span class="cart_qty_cls">{{ cartItems?.items?.length }}</span>
      <ul class="show-div shopping-cart" *ngIf='!cartItems?.items?.length' [ngClass]="sidecart ? 'active' : ''">
        <div class="minicart-title-block d-flex justify-content-between align-items-center">
          <h3>MiniCart</h3>
          <span class="closebtn" title="Close" (click)="sidecartToggle()"><i class="fa fa-times"></i></span>
        </div>
        <h5>Your cart is currently empty.</h5>
      </ul>
      <ul class="show-div shopping-cart" *ngIf='cartItems?.items?.length' [ngClass]="sidecart ? 'active' : ''">       
        <div class="minicart-title-block d-flex justify-content-between align-items-center">
          <h3>MiniCart</h3>
          <span class="closebtn" title="Close" (click)="sidecartToggle()"><i class="fa fa-times"></i></span>
        </div>
        <li *ngFor="let item of cartItems?.items">
          <div class="media">
            <a [routerLink]="['/product', item.url_key]">
              <img class="me-3" [src]="item.product?.base_image?.small_image_url" [alt]="">
            </a>
            <div class="media-body">
              <a  [routerLink]="['/product', item.url_key]">
                <h4>{{ item?.name }}</h4>
              </a>
              <div>
                <div class="price">
                  <small>
                    {{ item?.quantity }} x 
                    {{ item?.price | currency:cartService?.Currency.currency:'symbol' }}
                  </small>
                </div>
                <div [class]="item.additional.attributes ? 'attribute' : '' " *ngIf="item.additional.attributes">
                  <span *ngFor="let attribute of item.additional.attributes">
                    <small>{{attribute.name}} : {{attribute.options.name}}</small>
                  </span>
                </div>
              </div>
            </div>
            <div class="media-side">
              <div class="subtotal">
                {{ item.total | currency:cartService?.Currency.currency:'symbol' }}
              </div>
            </div>
          </div>
          <div class="close-circle" (click)="removeItem(item)">
            <a href="javascript:void(0)"><i class="fa fa-times" aria-hidden="true"></i></a>
          </div>
        </li>
        <li>
          <div class="total">
            <h5>Subtotal : <span>{{ cartItems.sub_total  | currency:cartService?.Currency.currency:'symbol' }}</span></h5>
          </div>
        </li>
        <!-- <li>
          <div class="total">
            <h5>Total : <span>{{ cartItems.grand_total | currency:productService?.Currency.currency:'symbol' }}</span></h5>
          </div>
        </li> -->
        <li>
          <div class="buttons">
            <a [routerLink]="['/checkout/cart']" class="view-cart" (click)="sidecartToggle()">view cart</a>
            <a [routerLink]="['/checkout/checkout']" class="checkout" (click)="sidecartToggle()">checkout</a>
          </div>
        </li>
      </ul>
      
    </li>
  </ul>
</div>

