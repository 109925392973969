<div *ngIf="!loader">
  <div class="img-wrapper">
    <div class="offers-new" *ngIf="product.extra_attributes?.offers?.length > 0"><span>{{product.extra_attributes.offers[0].text}}</span></div>
    <div class="lable-block">
      <span class="lable3" *ngIf="product.new">new</span>
      <span class="lable4" *ngIf="product.sale">on sale</span>
    </div>
    <div class="front">
      <a [routerLink]="['/product/', product.url_key]" [queryParams]="queryParams" [queryParamsHandling]="'merge'" [state]="{product: product}">
        <img 
          [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder-thumb.jpg'" 
          [lazyLoad]="ImageSrc ? ImageSrc : product.base_image.medium_image_url" 
          class="img-fluid lazy-loading" 
          alt="{{ product.name }}" />
      </a>
    </div>
   
    <!--<div class="cart-info cart-wrap" *ngIf="enableButton">
     
      <a href="javascript:void(0)" title="Add to Wishlist" (click)="addToWishlist(product)">
        <i class="ti-heart" aria-hidden="true"></i>
      </a>
      <a href="javascript:void(0)" title="Quick View" (click)="QuickView.openModal()">
        <i class="ti-search" aria-hidden="true"></i>
      </a>
      <a href="javascript:void(0)" title="Compare" (click)="addToCompare(product)">
        <i class="ti-reload" aria-hidden="true"></i>
      </a>
    </div>-->
  </div>
  <div class="product-detail">
    <div>  
      <div class="brand" *ngIf="product.show_brand">
        <img src="{{product.brand.logo}}" [alt]="product.brand.name">
      </div>    
      <!-- <h6>
        <a [routerLink]="['/product/', product.url_key]" [queryParams]="queryParams" [queryParamsHandling]="'merge'" [state]="{product: product}" class="product-name">
        {{ product?.name | titlecase | truncate : 40 : false : '...' }}
        </a>
      </h6> -->
      <h6>
        <a [routerLink]="['/product/', product.url_key]" [queryParams]="queryParams" [queryParamsHandling]="'merge'" [state]="{product: product}" class="product-name">
        {{ product?.name | titlecase }}
        </a>
      </h6>
      <!-- <h4>
        {{ product?.price * currency?.price | discount:product | currency:currency?.currency:'symbol' }}
        <del *ngIf="product?.discount"><span class="money"> {{ product?.price * currency?.price | currency:currency?.currency:'symbol' }}</span></del>
      </h4> -->
      <h4>
        <ng-container *ngIf="product?.special_price != null">         
          {{ product?.special_price * currency?.price | currency:currency?.currency:'symbol' }}         
          <del ><span class="money"> {{  product?.price * currency?.price | currency:currency?.currency:'symbol' }}</span></del>
        </ng-container>
        <ng-container *ngIf="product?.special_price === null">
          {{  product?.price * currency?.price | currency:currency?.currency:'symbol' }}
        </ng-container>
      </h4>
      <div class="variant-options" *ngIf="enableCart">
        <ng-container *ngFor="let attribute of product.super_attributes">
          
          <div class="add_quantitypro" *ngIf="attribute.attribute_type == 'select'">
            
            <select [(ngModel)]="attribute.selectedValue" [ngModelOptions]="{standalone: true}" (change)="changeVariant(attribute, attribute.selectedValue)">
              <option value="">Choose an option</option>
              <ng-container *ngFor="let option of attribute.options">
                <!-- <option [value]="option.id" >{{option.label}}</option> -->
                <option [ngValue]="option" [selected]="option.selected">{{option.label}}</option>
              </ng-container>            
            
            </select>
          
          </div>
          
          <div class="color-option" *ngIf="attribute.attribute_type == 'swatch'" [attr.data-product-id]="product.id">
            <ul class="color-variant">
              <ng-container *ngFor="let option of attribute.options; let i=index;" >                
                <li [class]="option.code" [class.active]="option.selected" [ngStyle]="{'background-color': option.swatch_value}"
                  (click)="changeVariant(attribute, option)" [attr.data-color-id]="option.id" [tooltip]="option.name" [position]="'below'">
                  <input type="hidden" [name]="option.code" [(ngModel)]="attribute.selectedValue">            
                </li>
              </ng-container>
            </ul>
            <!-- <p *ngIf="errorTxt[attribute.code]" class="text-danger mb-0"> {{ errorTxt[attribute.code][0] }} -->
          </div>
        </ng-container>
      </div>     
      <div class="slider-images" *ngIf="product.extra_attributes?.images?.length">
      
        <!-- <div *ngIf="product.extra_attributes?.images.length > 4 else elseBlock"> -->
        <div *ngIf="product.extra_attributes?.images.length > 5 else elseBlock">
          <owl-carousel-o [options]="sliderConfig">
            <ng-container *ngFor="let item of product.extra_attributes.images;">
              <ng-template carouselSlide><!--[width]="width"-->             
                <img src="{{item.image}}" />
              </ng-template>
            </ng-container>
        </owl-carousel-o>
          
      </div>
        <ng-template #elseBlock>
          <div class="d-flex">
            <div *ngFor="let item of product.extra_attributes?.images;" class="image-item" [class]="item.code">
                   
              <div [class]="item.code">
                <img src="{{item.image}}" />
              </div>
            
            </div>
          </div>
          
          
        </ng-template>
       
      </div>
     
      <div class="add_tocart" *ngIf="enableButton">
        
        <a href="javascript:void(0)" title="Add to cart" (click)="addToCart()"
        *ngIf="cartModal">
        Add To Cart
        </a><!--<i class="ti-shopping-cart"></i>-->
      </div>
    </div>
  </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>

