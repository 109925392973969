import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { tap, map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { User } from './user.model';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user = new BehaviorSubject<User>(null);
  private tokenExpirationTimer: any;
  public isAuth: boolean = false;
  private _authToken: string = '';

  constructor(
    private router: Router,
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { 
    
  }

  set authToken(_value: string) {
    ///const userData = JSON.parse(localStorage.getItem('userData') || '{}');
    //this.authToken = _.get(userData, '_token');
    this._authToken = _value;
    this.isAuth = !!this.authToken;
  }

  get authToken(): string{    
    return this._authToken ?? null;
  }
  
  signIn(username: String, password: String, device_id: String) {
    let url = `${environment.baseUrl}${'/customer/login'}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
      })
    };
    return this.http
      .post<any>(
       url,
        {
          token: true,
          accept_token: true,
          email: username,
          password: password,
          device_name: device_id
        },
        httpOptions
      )
      .pipe(
        tap(resData => { 
          this.authToken = resData.token;  
          const tokenExpiry = new Date(
            new Date().getTime() + 3600 * 1000
          );
         
          const user = new User(resData.data.email, resData.data.first_name
            , resData.data.last_name, resData.data.name, resData.data.phone, resData.data.id, resData.token, tokenExpiry);

          this.user.next(user);
          this.isAuth = true;          
          this.autoLogout(3600 * 1000);
          this.isAuth = true;
          if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem('userData', JSON.stringify(user));
          }
          //this.router.navigate(['/']);
        })
      );
  }
  autoSignIn() {
    const userData: {
      id: string,
      first_name: string,
      last_name: string,
      name: string,
      phone: string,
      email: string,
      _token: string,
      _tokenExpirationDate: string
    } = isPlatformBrowser(this.platformId) ? JSON.parse(localStorage.getItem('userData')): null;
    
    if (!userData) {
      return;
    }

    const loadedUser = new User(userData.email, userData.first_name, userData.last_name, userData.name, userData.phone, userData.id, userData._token, new Date(userData._tokenExpirationDate));
    
    if (loadedUser.token) {
      this.authToken = loadedUser.token;
      this.isAuth = true;
      this.user.next(loadedUser);
      const expirationDuration = new Date(userData._tokenExpirationDate).getTime() - new Date().getTime();
      this.autoLogout(expirationDuration);
    }

  }
  signUp(f_name: String, l_name: String, phone: String, email: String, password: String, confirm_password: String) {
    let url = `${environment.baseUrl}${'/customer/register'}`;
   
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
      })
    };
    return this.http.post<any>(
      url,
      {
        first_name: f_name,
        last_name: l_name,
        phone: phone,
        email: email,
        password: password,
        password_confirmation: confirm_password
      },
      httpOptions
    );
  }
  passwordForgotten(email: string){
    let url = `${environment.baseUrl}${'/v1/password/email'}`;
    return this.http.post<any>(url, {
        email: email,
      });
  }


  logout() {   
    let url = `${environment.baseUrl}${'/customer/logout'}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + this.authToken
      })
    };
    return this.http.post<any>(url, {
      token: this.authToken
    }, httpOptions).pipe(
      //map((res) => res.body),
      tap(res => {       
        if(res.status){
          this.clearLogout();
        }
      })
    )
    
  }
  clearLogout(){
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem('userData');
      //localStorage.removeItem('setCartItems');
    }
    this.user.next(null);
    this.authToken = '';
    this.isAuth = false;    
    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
    }
    this.tokenExpirationTimer = null;

    this.router.navigate(['/']);
    
  }
  autoLogout(expirationDuration: number) {
    this.tokenExpirationTimer = setTimeout(() => {
      this.logout();
    }, expirationDuration);
  }
}
