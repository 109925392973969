import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit {
  
  public themeLogo: string = 'assets/images/logo.png'; // Change Logo  icon/logo-10.png
  constructor() { }

  ngOnInit(): void {
  }

}
