<!-- price filter start here -->
<div class="collection-filter-block">    
    <div class="collection-collapse-block price-filter-collapse" [class.open]="collapse">
        <h3 class="collapse-block-title" (click)="toggleCollapse()">Price</h3>
        <div class="collection-collapse-block-content">
            <div class="collection-brand-filter">
                <div class="mt-2">
                    <ng5-slider 
                        [(value)]="min" 
                        [(highValue)]="max" 
                        [options]="options"
                        (userChange)="onUserChange($event)" 
                        (userChangeEnd)="appliedFilter($event)"
                        *ngIf="isBrowser">
                    </ng5-slider>
                </div>
            </div>
        </div>
    </div>
</div>