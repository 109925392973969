<div class="main-navbar">
  <div id="mainnav">
    <div class="toggle-nav" (click)="mainMenuToggle()">
      <i class="fa fa-bars sidebar-bar"></i>
    </div>
    <ul class="nav-menu" [class.opennav]="navServices?.mainMenuToggle">

      <li class="back-btn">
        <div class="mobile-back text-end" (click)="mainMenuToggle()">
          <span>Back</span>
          <i class="fa fa-angle-right ps-2" aria-hidden="true"></i>
        </div>
      </li>

      <li *ngFor="let menuItem of menuItems" [class.mega-menu]="menuItem.megaMenu" [class.active]="menuItem.active">
        <!-- Sub -->
        <a href="javascript:void(0)" class="nav-link sub" *ngIf="menuItem.show_menu && menuItem.type === 'sub'" 
        (click)="toggletNavActive(menuItem)" >
          {{ menuItem.title | translate }}
          <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
          <span class="sub-arrow" *ngIf="menuItem.children.length && isDesktop"></span>
        </a>
        <!-- Link -->
        <a [routerLink]="['/', menuItem.slug]" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link link"
          *ngIf="menuItem.show_menu && menuItem.type === 'link'">
          {{ menuItem.title }}
          <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
          <span class="sub-arrow" *ngIf="menuItem.children.length && isDesktop"></span>
        </a>
        <span class="sub-arrow" *ngIf="menuItem.children.length && isMobile" (click)="toggletNavActive(menuItem)"></span>
        <!-- 2nd Level Menu -->
        <ul class="nav-submenu" [class.opensubmenu]="menuItem.active" *ngIf="menuItem.children.length && !menuItem.megaMenu">
          <li *ngFor="let childrenItem of menuItem.children" [class.active]="childrenItem.active">
            <!-- Sub -->
            <a href="javascript:void(0)" *ngIf="childrenItem.show_menu && childrenItem.type === 'sub'" 
                (click)="toggletNavActive(childrenItem)">
              {{ childrenItem.title | translate }}
              <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span>
              <span class="sub-arrow" *ngIf="childrenItem.children.length && isDesktop"></span>
            </a>
            <!-- Link -->
            <a [routerLink]="['/' + childrenItem.path]" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" 
              *ngIf="childrenItem.show_menu && childrenItem.type === 'link' ">
              {{ childrenItem.title | translate }}
              <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span>
              <span class="sub-arrow" *ngIf="childrenItem.children.length && isDesktop"></span>
            </a>
            <span class="sub-arrow" *ngIf="childrenItem.children.length && isMobile" (click)="toggletNavActive(childrenItem)"></span>

            <!-- 3rd Level Menu -->
            <ul *ngIf="childrenItem.children.length" [class.opensubchild]="childrenItem.active" class="nav-sub-childmenu">
              <li *ngFor="let childrenSubItem of childrenItem.children">
                <!-- Link -->
                <a [routerLink]="['/', menuItem.slug, childrenItem.slug, childrenSubItem.slug]" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" 
                  *ngIf="childrenSubItem.show_menu && childrenSubItem.type === 'link' ">
                  {{ childrenSubItem.title | translate }}
                  <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                </a>
                
              </li>
            </ul>
          </li>
        </ul>

        <div class="mega-menu-container" [class.opensubmenu]="menuItem.active" *ngIf="menuItem.megaMenu && menuItem.children" [ngClass]="{'mobile': device.isMobile() || device.isTablet()}">
          <div class="container">
            <div class="row">
              <ng-container *ngFor="let childrenItem of menuItem.children; let i = index; let c = count;">
                
                <div class="mega-box" [ngClass]="[c > 4 ? 'col-3' : 'col']" [class.active]="childrenItem.active" *ngIf="menuItem.children[i]?.children?.length" >
                  
                  <div class="link-section" *ngIf="childrenItem.show_menu">
                    <div class="menu-title">
                      <h5>
                        <a href="javascript:void(0)" *ngIf="childrenItem.show_menu && childrenItem.type === 'sub'" 
                        (click)="toggletNavActive(childrenItem)">
                          {{ childrenItem.title | translate }}
                          <span class="sub-arrow" *ngIf="childrenItem.children && isDesktop"></span>
                        </a>
                        <a [routerLink]="['/' + childrenItem.path]" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" 
                        *ngIf="childrenItem.show_menu && childrenItem.type === 'link'" >
                          {{ childrenItem.title | translate }}
                          <span class="sub-arrow" *ngIf="childrenItem.children && isDesktop"></span>
                        </a>
                        <span class="sub-arrow" *ngIf="childrenItem.children.length && isMobile" (click)="toggletNavActive(childrenItem)"></span>
                      </h5>
                    </div>
                    <div class="menu-content" [class.opensubmegamenu]="childrenItem.active">
                      <ul *ngIf="childrenItem.children">
                        <li *ngFor="let childrenSubItem of childrenItem.children">
                          <!-- Sub -->
                            <a href="javascript:void(0)" *ngIf="childrenSubItem.show_menu && childrenSubItem.type === 'sub'">
                              {{ childrenSubItem.title | translate }}
                              <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                            </a>
                            <!-- Link -->
                            <a [routerLink]="['/' + childrenSubItem.path]" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" 
                              *ngIf="childrenSubItem.show_menu && childrenSubItem.type === 'link' ">
                              {{ childrenSubItem.title | translate }}
                              <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                            </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </ng-container>
              
            </div>
          </div>
        </div>
      </li>
      <li>
        <a [routerLink]="['/blog']" class="nav-link sub">Blog</a>
      </li>
      <li>
        <a [routerLink]="['/store-locator']" class="nav-link sub">Store Locator</a>
      </li>
      <li>
        <a [routerLink]="['/contact']" class="nav-link sub">Contact</a>
      </li>
    </ul>
  </div>
</div>
