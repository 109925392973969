import { CartService } from './../../services/cart.service';
import { Component, OnInit, Injectable, PLATFORM_ID, Inject, Renderer2 } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { filter, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from "../../services/product.service";
//import { Product } from "../../classes/product";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  //public products: Product[] = [];
  public search: boolean = false;
  public sidecart: boolean = false;
  public cartItems: any = [];
  
  public languages = [{ 
    name: 'English',
    code: 'en'
  }, {
    name: 'French',
    code: 'fr'
  }];

  public currencies = [{
    name: 'Euro',
    currency: 'EUR',
    price: 0.90 // price of euro
  }, {
    name: 'Rupees',
    currency: 'INR',
    price: 70.93 // price of inr
  }, {
    name: 'Pound',
    currency: 'GBP',
    price: 0.78 // price of euro
  }, {
    name: 'Dollar',
    currency: 'USD',
    price: 1 // price of usd
  }]
  
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private translate: TranslateService,
    public cartService: CartService,
    private renderer:Renderer2,
  ) { 

    //this.productService.cartItems.subscribe(response => this.products = response);
   
  }

  ngOnInit(): void {
    this.cartService.getCart();    
    this.cartService.allCartItems.subscribe(response => this.cartItems = response);
    //console.log(this.cartItems);
  }

  searchToggle(){
    this.search = !this.search;
  }
  
  sidecartToggle(){
    this.sidecart = !this.sidecart;
    if(this.sidecart===true) {
      this.renderer.addClass(document.body, 'minicart_active');
    }
    else {
      this.renderer.removeClass(document.body, 'minicart_active');
    }
  }

  changeLanguage(code){
    if (isPlatformBrowser(this.platformId)) {
      this.translate.use(code)
    }
  }

  // get getTotal(): Observable<number> {
  //   return this.cartService.cartTotalAmount();
  // }

  removeItem(product: any) {
    this.cartService.removeCartItem(product);
    this.sidecart = false;
    this.renderer.removeClass(document.body, 'minicart_active');
  }

  // changeCurrency(currency: any) {
  //   this.productService.Currency = currency
  // }

}

