<!--footer section -->
<footer [class]="class">
	<div class="light-layout" *ngIf="newsletter">
		<div class="container">
			<section class="small-section border-section newsltr-sec border-top-0">
				<div class="row">
					<div class="col-lg-7">
						<div class="subscribe">
							<div>
								<h4 class="text-black">NEWSLETER SIGN UP</h4>
								<p class="text-black">Subscribe our newsletter to get latest new about our new product and promo campaign.</p>
							</div>
						</div>
					</div>
					<div class="col-lg-5">
						<form class="form-inline subscribe-form" action="#" target="_blank" method="post"><!--https://pixelstrap.us19.list-manage.com/subscribe/post?u=5a128856334b598b395f1fc9b&amp;id=082f74cbda-->
							<div class="form-group mx-sm-3">
								<input type="email" #name name="EMAIL" id="mce-EMAIL" class="form-control" placeholder="Enter your Email Address">
							</div>
							<button type="submit" class="btn btn-solid">subscribe</button>
						</form>
					</div>
				</div>
			</section>
		</div>
	</div>
	<section class="section-b-space light-layout light-layout-ftr-top">
		<div class="container">
			<div class="row footer-theme partition-f">
				<!-- <div class="col-lg-4 col-md-6"> -->
					<!--<div class="footer-title footer-mobile-title">
						<h4>about</h4>
					</div>-->
					<!-- <div class="sub-title">
						<div class="footer-title">
							<h4 class="text-black">Who We Are?</h4>
						</div>
					</div>
					<div class="footer-contant"> -->
						<!-- <div class="footer-logo">
							<img [src]="themeLogo" alt="logo">
						</div> -->
						<!-- <p>Business Direct is a concept of bedandpillows.com. We strive to make your purchasing experience convenient, simple and stress free so that you can focus on what you do best. bedandpillows.com is unique with exceptional customer service, extensive product assortment, nationwide distribution and fast delivery. We have dedicated customer support team is committed to assist your needs today and in the future.</p>
					--><!-- <div class="footer-social"> -->
							<!--<ul>
								<li>
									<a href="https://www.facebook.com/Bed.Pillows.Mattress" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a>
								</li>
								<li>
									<a href="https://twitter.com/BedPillowsUAE" target="_blank"><i class="fa fa-twitter" aria-hidden="true"></i></a>
								</li>
								<li>
									<a href="https://www.linkedin.com/company/bed-and-pillows" target="_blank"><i class="fa fa-rss" aria-hidden="true"></i></a>
								</li>
								<li>
									<a href="javascript:void(0)"><i class="fa fa-instagram" aria-hidden="true"></i></a>
								</li> 
							</ul>
						</div>-->
					<!-- </div>
				</div> -->
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
                            <h4 class="text-black">Shop by Collection</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li><a [routerLink]="['/mattresses/shop-by-brand/americanstar']">Americanstar</a></li>
								<li><a [routerLink]="['/mattresses/shop-by-brand/bed-story']"> Bed Story</a></li>
								<li><a [routerLink]="['/mattresses/shop-by-brand/b-p-mattress']">B & P</a></li>
								<li><a [routerLink]="['/mattresses/shop-by-brand/fansace']">Fansace</a></li>
								<!-- <li><a [routerLink]="['/']">Lustre</a></li> -->
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col"><!-- offset-xl-1-->
					<div class="sub-title">
						<div class="footer-title">
                            <h4 class="text-black">Useful Links</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li><a [routerLink]="['/about-us']">About Us</a></li>
								<li><a [routerLink]="['/store-locator']">Store Location</a></li>
								<li><a [routerLink]="['/contact']">Contact</a></li>
								<li><a [routerLink]="['/blog']">Blog</a></li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
                            <h4 class="text-black">Information</h4><!--Extras-->
                        </div>
                        <div class="footer-contant">
                            <ul>
                                
								<li><a [routerLink]="['/terms-and-conditions']">Terms & Conditions</a></li>
								<li><a [routerLink]="['/privacy-policy']">Privacy Policy</a></li>
								<li><a [routerLink]="['/return-refund-policy']">Return & Refund Policy</a></li>
                                <li><a [routerLink]="['/delivery-information']">Delivery Information</a></li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
						    <h4 class="text-black">Contact Info</h4>
						</div>
						<div class="footer-contant">
						    <ul class="contact-list">
						        <li><i class="fa fa-map-marker"></i>Near Belhasa Driving Center, Next to MedX Nutrition, Shop No.4,Nadd AL Hamar, Dubai, UAE.</li>
						        <li><i class="fa fa-phone"></i><a href="tel:+97144514570">+97144514570</a> <br/><a href="tel:+971504739900">+971504739900</a></li>
						        <li><i class="fa fa-envelope-o"></i><a href="mailto:info@bedandpillows.com">info@bedandpillows.com</a></li>
						    </ul>
							<div class="footer-social">
								<ul>
									<li>
										<a href="https://www.facebook.com/Bed.Pillows.Mattress" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a>
									</li>
									<li>
										<a href="https://twitter.com/BedPillowsUAE" target="_blank"><i class="fa fa-twitter" aria-hidden="true"></i></a>
									</li>
									<li>
										<a href="https://www.linkedin.com/company/bed-and-pillows" target="_blank"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
									</li>
									<!-- <li>
										<a href="javascript:void(0)"><i class="fa fa-instagram" aria-hidden="true"></i></a>
									</li> -->
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<div class="sub-footer">
		<div class="container">
			<div class="row">
                <div class="col-xl-6 col-md-7 col-sm-12">
                  <div class="footer-end">
                    <p>Bed and Pillows <i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}}. All Rights Reserved.</p>
                  </div>
                </div>
                <div class="col-xl-6 col-md-5 col-sm-12">
                  <div class="payment-card-bottom">
                    <ul>
                      <li>
                        <a><img src="assets/images/icon/visa.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/mastercard.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/paypal.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/american-express.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/discover.png" alt=""></a>
                      </li>
                    </ul>
                  </div>
                </div>
            </div>
		</div>
	</div>
</footer>
<!--footer section end -->