import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSegment, PreloadAllModules } from '@angular/router';


import { HomeComponent } from './home/home.component';
import { CategoryResolver } from './categories/resolver/category.resolver';
import { ProductResolver } from './product/resolver/product.resolver';
import { DefaultLayoutComponent } from './default-layout/default-layout.component';

const routes: Routes = [
  
  {
    path: '',
    component: DefaultLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: '/',
        pathMatch: 'full'
      },
      {
        path: '',        
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
      },
       
      {
        path: '',           
        loadChildren: () => import('./product/product.module').then(m => m.ProductModule),
        data: { preload: false, delay: false },
      },
      { 
        path: '',
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) 
      },
      { 
        path: '',
        loadChildren: () => import('./account/account.module').then(m => m.AccountModule) 
      },
      {
        path: '',
        loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule)
      },  
      {
        path: '',
        loadChildren: () => import('./categories/categories.module').then(m => m.CategoriesModule),
        data: { preload: false, delay: false },
      },
      {
        path: '**', 
        redirectTo: '', 
      },
    ]
  },  
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy',
    //preloadingStrategy: PreloadAllModules
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

