import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject, map, Observable, Subscription } from 'rxjs';
import { Category } from 'src/app/core/model/category.model';
import { ApiService } from './api.service';

// Menu
export interface Menu {
	slug?: string;
	path?: string;
	title?: string;
	type?: string;
	show_menu?: boolean;
	megaMenu?: boolean;
	image?: string;
	active?: boolean;
	badge?: boolean;
	badgeText?: string;
	children?: Menu[];
	level: number;
}

@Injectable({
	providedIn: 'root'
})

export class NavService {
	categories: Category[] = [];
  	categoriesSubs: Subscription;
	
	constructor(
		private api: ApiService,		
	) { 
		
		//this.fetchMenu();
	}

	public screenWidth: any;
	public leftMenuToggle: boolean = false;
	public mainMenuToggle: boolean = false;

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

		
	items = new BehaviorSubject<Menu[]>(null);
	fetchMenu() {
		return this.api
			.getAll<any>('menu-categories')
			.subscribe((response) => {		
				let menuList: Menu[] = response;
				menuList?.filter((menu) => {				
					if(menu.type === 'sub'){
						menu.active = false
					}
				})
				//console.log('MenuList',menuList);
				this.items.next(menuList);
			});
	}
	menuCategory(): Observable<any> {
		return this.api
		  .getAll<any>('menu-categories')
		  .pipe(map((response) => response));
	  }

}
