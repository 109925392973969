import { Component, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './auth/auth.service';
import { CookieService } from 'ngx-cookie';
import { NavService } from './shared/services/nav.service';
import { Meta } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  //For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );
  
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService, 
    translate: TranslateService,
    private meta: Meta,
    private navService: NavService,
    private authService: AuthService,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      translate.setDefaultLang('en');
      translate.addLangs(['en', 'fr']);
    }
  }

  ngOnInit(){
    if (isPlatformBrowser(this.platformId)) {
      this.authService.autoSignIn();
      this.navService.fetchMenu();
      
      if(environment.staging){
        this.meta.updateTag({ name: 'robots', content: 'noindex, nofollow' });
      }
    }
  }

}
