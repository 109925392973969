import { Component, OnInit } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { Router } from '@angular/router';
import { take } from 'rxjs';
import { DeviceDetectorService } from 'src/app/core/services/device-detector.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  public isMobile: boolean;
  public isDesktop: boolean;
  public menuItems: Menu[];

  constructor(private router: Router, public navServices: NavService, public device: DeviceDetectorService) {    
    this.navServices.items.subscribe(menuItems => {
      this.menuItems = menuItems;
      // this.menuItems?.filter(menu => {
      //   if((this.device.isMobile() || this.device.isTablet()) && menu.level == 1){
      //     menu.type = 'sub'
      //   }
      //   if((this.device.isMobile() || this.device.isTablet()) && menu.children?.find(e => e.children?.length && e.level == 2)){
      //     menu?.children.filter(e => e.level == 2 ? e.type= 'sub' : e.type= 'link')
      //   }
       
      // })
      
      
    });
    //this.navServices.items.subscribe(menuItems => this.menuItems = menuItems );
    //this.navServices.menuCategory().subscribe(menuItems => this.menuItems = menuItems );
   
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });
    this.isMobile = this.device.isMobile() || this.device.isTablet();
    this.isDesktop = this.device.isDesktop();
  }

  ngOnInit(): void {    
  }

  mainMenuToggle(): void {
    this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
  }

}
