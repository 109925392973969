import { Component, OnInit, Input, HostListener } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { CategoryService } from 'src/app/core/services/category.service';

@Component({
  selector: 'app-header-one',
  templateUrl: './header-one.component.html',
  styleUrls: ['./header-one.component.scss']
})
export class HeaderOneComponent implements OnInit {
  
  @Input() class: string;
  @Input() themeLogo: string = 'assets/images/logo.png'; // Default Logo icon/logo.png
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false
  
  public stick: boolean = false;

  private userSub: Subscription;
  isAuthenticated: Boolean = false;
  userId: string;

  constructor(
    private categoryService: CategoryService, 
    private authService: AuthService,
    private toastService: ToastrService
  ) { }

  ngOnInit(): void {
    
    this.userSub = this.authService.user.subscribe((user) => {     
      this.isAuthenticated = !!user;
      const isAuth = !!user;
      if(isAuth) {
          //this.userId = user.id;
      }
      
    });
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  	if (number >= 150 && window.innerWidth > 400) { 
  	  this.stick = true;
  	} else {
  	  this.stick = false;
  	}
  }

  onLogout() {
    this.authService.logout().subscribe(res => {
      if(res.status){
        this.toastService.success(res.message);
      }
    });
  }

}
